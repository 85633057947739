<template>
  <div class="continer">
    <div class="topBar">
      <div class="logo" @click="backHome"></div>
      <div class="bar fr">
        <router-link to="/productCenter" class="menu tit2">产品中心</router-link>
        <span class="line line2"></span>
        <router-link to="/solution" class="menu tit3">解决方案</router-link>
        <span class="line line3"></span>
        <router-link to="/support" class="menu tit4">支持&服务</router-link>
        <span class="line line4"></span>
        <router-link to="/about" class="menu tit5">关于宁讯</router-link>
        <span class="line line5"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'topBarT',
  methods: {
    backHome() {
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped>
.continer {
  width: 1920px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0);
}

.continer:hover {
  background-color: #f5f5f5;
}

.continer:hover .logo {
  background: url(../assets/common/pic/logoRB.png) no-repeat;
  background-size: 154px;
}

.continer:hover .bar .menu {
  color: #333;
}

.continer:hover .line {
  background-color: #333;
}

.continer .topBar {
  position: relative;
  width: 1400px;
  height: 70px;
  margin: 0 auto;
}

.topBar .logo {
  display: inline-block;
  width: 160px;
  height: 55px;
  margin-top: 8px;
  background: url(../assets/common/pic/logoRW.png) no-repeat;
  background-size: 154px 52px;
}

.topBar .logo:hover {
  cursor: pointer
}

.topBar .bar {
  line-height: 70px;
  font-size: 16px;
  font-family: 'Microsoft Yahei';
  color: #f5f5f5;
  display: inline-block;
}

.topBar .fr {
  float: right; 
}

.topBar .bar .menu {
  display: inline-block;
  height: 70px;
  margin-left: 30px;
  color: #f5f5f5;
}

.topBar .line2 {
  position: absolute;
  right: 297px;
  top: 68px;
  width: 0;
  height: 0;
  background-color: #f5f5f5;
}

.topBar .line3 {
  position: absolute;
  right: 202px;
  top: 68px;
  width: 0;
  height: 0;
  background-color: #f5f5f5;
}

.topBar .line4 {
  position: absolute;
  right: 93px;
  top: 68px;
  width: 0;
  height: 0;
  background-color: #f5f5f5;
}

.topBar .line5 {
  position: absolute;
  right: 0;
  top: 68px;
  width: 0;
  height: 0;
  background-color: #f5f5f5;
}

.topBar .tit2:hover+.line2 {
  width: 63px;
  height: 2px;
}

.topBar .tit3:hover+.line3 {
  width: 63px;
  height: 2px;
}

.topBar .tit4:hover+.line4 {
  width: 78px;
  height: 2px;
}

.topBar .tit5:hover+.line5 {
  width: 63px;
  height: 2px;
}
</style>
