<template>
  <div>
    <!-- banner区域 Start -->
    <div class="banner">
      <topBarT />
      <div class="content animate__animated animate__fadeOut">
        <div class="txt">
          <div class="ningxun"><b>宁&nbsp;讯&nbsp;科&nbsp;技</b></div>
          <div class="tit">专&nbsp;注&nbsp;无&nbsp;线&nbsp;智&nbsp;能&nbsp;产&nbsp;品&nbsp;及&nbsp;方&nbsp;案</div>
          <div class="btn" @click="aboutClick">了&nbsp;解&nbsp;更&nbsp;多</div>
        </div>
      </div>
    </div>
    <!-- banner区域 End -->
    <!-- product区域 Start -->
    <div class="product">
      <div class="tit">产&nbsp;品&nbsp;中&nbsp;心</div>
      <div class="continer">
        <router-link to="product" class="box mr">
          <div class="caption">
            <img src="../../assets/home/wireless.png" alt=""><br />
            <div>工业无线传输覆盖系列</div>
          </div>
          <div class="txt">
            <div>吸顶式无线接入点<br />无线接入点控制器<br />工业级室外无线接入点</div>
          </div>
          <div class="more">+</div>
        </router-link>
        <router-link to="storageCabinet" class="box mr">
          <div class="caption">
            <img src="../../assets/home/cabinet.png" alt=""><br />
            <div>智能无线保管柜系列</div>
          </div>
          <div class="txt">
            <div>智能无线保管柜说明<br />模组设备介绍<br />智能网关介绍</div>
          </div>
          <div class="more">+</div>
        </router-link>
        <router-link to="platform" class="box">
          <div class="caption">
            <img src="../../assets/home/msg.png" alt=""><br />
            <div>无线网管平台软件系列</div>
          </div>
          <div class="txt">
            <div>智能维护平台<br />中间件平台<br />数字孪生</div>
          </div>
          <div class="more">+</div>
        </router-link>
      </div>
    </div>
    <!-- product区域 End -->
    <!-- 解决方案区域 Start -->
    <swiper />
    <!-- 解决方案区域 End -->
    <!-- 联系我们区域 Start -->
    <div class="contact">
      <div class="tit">联&nbsp;系&nbsp;我&nbsp;们</div>
      <div class="wx our">微信公众号</div>
      <div class="wxpic ourpic">
        <img src="../../assets/common/pic/publicAccount.jpg" alt="">
      </div>
      <div class="sheet">
        <form id="fs-frm" name="complaint-form" accept-charset="utf-8" action="https://formspree.io/f/xlekgbkj"
          method="post">
          <fieldset id="fs-frm-inputs">
            <!-- <label for="full-name">您的姓名</label> -->
            <input type="text" name="name" id="full-name" placeholder="由此键入您的姓名" required="">
            <!-- <label for="telephone">您的联系方式</label> -->
            <input type="telephone" name="telephone" id="telephone" placeholder="由此键入您的电话">
            <!-- <label for="email-address">您的邮箱</label> -->
            <input type="email" name="_replyto" id="email-address" placeholder="由此键入您的邮箱" required="">
            <!-- <label for="complaint">您的需求内容</label> -->
            <textarea rows="6" name="complaint" id="complaint" placeholder="由此键入您的需求内容" required=""></textarea>
            <input type="hidden" name="_subject" id="email-subject" value="Complaint Form Submission">
          </fieldset>
          <input type="submit" class="button" value="立&nbsp;即&nbsp;发&nbsp;送">
        </form>
      </div>
    </div>
    <!-- 联系我们区域 End -->
    <!-- 服务承诺区域 Start -->
    <div class="orientation">
      <div class="text fr">
        <div class="tit">服&nbsp;务&nbsp;承&nbsp;诺</div>
        <div class="txt">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司秉承专业、周到、热情的经营理念及服务态度,在为用户提供的专业规划设计中，会充分注重营收策略，充分体现“以客户为主导”的思想。特别重视前期的数据搜集整理、同行探讨、网络设计、测试以及与用户充分的交流，最大限度地保障项目的成功实施，帮助用户快速、经济地实现无线网络传输、覆盖和用户接入及相关应用,并承诺相关：
        </div>
        <div class="txtli">
          * 提供客户的需求沟通及方案设计
          &nbsp;&nbsp;&nbsp;* 提供工程师客户到现场环境的勘察<br>
          * 提供客户的相关的样品借测
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 提供客户产品技术安装培训<br>
          * 提供相关产品硬件三年质保
        </div>
      </div>
    </div>
    <!-- 服务承诺区域 End -->
    <!-- footer区域 Start -->
    <baseBar />
    <!-- footer区域 End -->
  </div>
</template>

<script>
import topBarT from '@/components/topBarT.vue'
import baseBar from '@/components/baseBar.vue'
import swiper from '@/components/swiper.vue'

export default {
  name: 'Home',
  components: {
    topBarT,
    baseBar,
    swiper,
  },
  props: {
    msg: String
  },
  methods: {
    aboutClick() {
      this.$router.push('about')
    }
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>

<style scoped>
/* 淡出动画 */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    /*初始状态 透明度为0*/
  }

  50% {
    opacity: 0;
    /*中间状态 透明度为0*/
  }

  100% {
    opacity: 1;
    /*结尾状态 透明度为1*/
  }
}

/* banner区域 Start */
.banner {
  width: 1920px;
  height: 900px;
  background: url(../../assets/home/banner.png) no-repeat;
  background-size: 1920px 900px;
}

.content {
  padding-top: 300px;
  text-align: center;
  -webkit-animation-name: fadeIn;
  /*动画名称*/
  -webkit-animation-duration: 2s;
  /*动画持续时间*/
  -webkit-animation-iteration-count: 1;
  /*动画次数*/
  -webkit-animation-delay: 0s;
  /*延迟时间*/
}

.content .txt {
  top: 300px;
  left: 794px;
  color: #f5f5f5;
}

.content .txt .ningxun {
  font-size: 48px;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
}

.content .txt .tit {
  font-size: 24px;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  margin-top: 10px;
  margin-bottom: 10px;
}

.content .txt .btn {
  margin: 0 auto;
  cursor: pointer;
  width: 150px;
  height: 50px;
  font-size: 18px;
  line-height: 50px;
  border-radius: 5px;
  background-color: #c3a35d;
}


/* banner区域 End */

/* product区域 Start */
.product .tit {
  text-align: center;
  color: #333;
  font-size: 28px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 40px;
}

.product .continer {
  text-align: center;
}

.product .continer .box {
  display: inline-block;
  text-align: left;
  width: 400px;
  height: 250px;
  padding: 15px;
  background-color: #fff;
  color: #333;
}

.product .continer .box:hover {
  box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
  transform: translate3d(0, -2px, 0);
  transition: all 0.2s linear;
}

.product .continer .caption div {
  margin-top: 5px;
  margin-bottom: 5px;
}

.product .continer .caption img {
  height: 38px;
}

.product .continer .caption {
  font-size: 18px;
  font-weight: 700;
  font-family: 'Microsoft Yahei';
  border-bottom: 2px solid #c3a35d;
}

.product .continer .mr {
  margin-right: 20px;
  margin-bottom: 20px;
}

.product .continer .box .more {
  float: right;
  margin-top: -15px;
  width: 35px;
  height: 35px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-size: 35px;
  line-height: 30px;
  text-align: center;
}

.product .continer .box .txt {
  margin-top: 10px;
  font-size: 20px;
}

.product .continer .box .txt div {
  margin-bottom: 15px;
}

/* product区域 End */

/* contact Start */

.contact {
  position: relative;
  width: 1920px;
  height: 478px;
}
.contact .tit {
  text-align: center;
  color: #333;
  font-size: 28px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 40px;
}

.contact .wx {
  font-size: 14px;
  color: #333;
}

.contact .our {
  position: absolute;
  top: 80px;
  right: 580px;
}

.contact .ourpic img {
  display: none;
  position: absolute;
  top: 80px;
  right: 480px;
  width: 100px;
  height: 100px;
}

.contact .our:hover+.ourpic img {
  display: block;
}

.contact .sheet {
  text-align: center;
}

.contact .sheet input {
  margin: 0 auto;
  display: block;
  margin-bottom: 15px;
  width: 550px;
  height: 30px;
  font-size: 14px;
  padding-left: 10px;
}

.contact .sheet textarea {
  width: 544px;
  height: 100px;
  font-size: 14px;
  padding: 10px;
  resize: none;
}

.contact .sheet .button {
  margin-top: 30px;
  margin-bottom: 40px;
  cursor: pointer;
  width: 564px;
  height: 50px;
  background-color: #c3a35d;
  border: none;
  border-radius: 5px;
  color: #f5f5f5;
  font-size: 18px;
}

/* contact End */

/* orientation Start */
.orientation {
  width: 1920px;
  height: 450px;
  background: url(../../assets/home/orientation.png);
  background-size: 1920px 500px;
}

.orientation .text {
  width: 960px;
  height: 450px;
  padding: 40px;
}

.orientation .tit {
  color: #f5f5f5;
  font-size: 28px;
  font-weight: 700;
}

.orientation .txt {
  color: #f5f5f5;
  width: 507px;
  max-height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 30px;
}

.orientation .txtli {
  color: #f5f5f5;
  font-size: 16px;
  line-height: 30px;
}
/* orientation End */
.nothing {
  height: 1200px;
}
</style>
