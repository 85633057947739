<template>
    <swiper :modules="modules" :slides-per-view="1" :space-between="10" navigation :pagination="{ clickable: true }"
        @swiper="onSwiper" @slideChange="onSlideChange" :autoplay="{ delay: 2500, disableOnInteraction: false }">
        <swiper-slide>
            <div class="solution">
                <div class="tit">解&nbsp;决&nbsp;方&nbsp;案</div>
                <div class="plan">
                    <router-link to="solution" class="continer">
                        <img src="../assets/home/video.jpg" alt="" class="pic">
                        <div class="text">
                            <div class="larger">视频传输应用</div>
                            <div class="big">无线视频传输应用（视距、固定点）</div>
                            <div class="small">宁讯信息推出的室外系列网桥可应用于1KM—60KM<br />的点对点、点对多点及移动无线视频传输，高带宽...</div>
                            <div class="big">移动MESH无线视频覆盖传输（非视距、移动点）</div>
                            <div class="small">先进的智能Mesh技术非视距抗干扰频率支持<br />基于802.11n的超高带宽Hopping&Mesh架构</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="solution">
                <div class="tit">解&nbsp;决&nbsp;方&nbsp;案</div>
                <div class="plan">
                    <router-link to="dataApplication" class="continer">
                        <img src="../assets/home/data.png" alt="" class="pic">
                        <div class="text">
                            <div class="larger">数据采集应用</div>
                            <div class="big">工业无线数传</div>
                            <div class="small">LINKOM推出的WIFI网桥结合4G/3G/GRPS数传产品<br />采用工业级设计无线模块，以嵌入式实时操作系统...</div>
                            <div class="big">物流无线覆盖传输</div>
                            <div class="small">LINKOM推出的WIFI网桥结合4G/3G/GRPS数传产品<br />采用工业级设计无线模块，以嵌入式实时操作系统...</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="solution">
                <div class="tit">解&nbsp;决&nbsp;方&nbsp;案</div>
                <div class="plan">
                    <router-link to="agvRobot" class="continer">
                        <img src="../assets/home/agv.png" alt="" class="pic">
                        <div class="text">
                            <div class="larger">移动机器无线覆盖传输</div>
                            <div class="big">AGV移动机器人覆盖漫游传输</div>
                            <div class="small">工业自动化的普及以及智能工厂的建设，AGV的落地<br />地使用已经成为一些智能工厂的标配生产工具，LI...
                            </div>
                            <div class="big">电力巡检覆盖传输</div>
                            <div class="small">变电站机器人巡检已经在国内逐渐推广及普及，LINK<br />OM智能室外无线移动移动切换基站搭配天线已经...
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';

// Import Swiper styles
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, A11y],
        };
    },
};
</script>

<style scoped>
.swiper-slide {
    width: 1920px;
    height: 500px;
    /* line-height: 300px;
    font-size: 30px;
    text-align: center; */
}

.solution {
    margin-top: 40px;
    width: 1920px;
    height: 500px;
    background: url(../assets/home/solution.png) no-repeat;
    background-size: 1920px 500px;
}

.solution .tit {
    text-align: center;
    line-height: 120px;
    font-size: 28px;
    font-weight: 700;
    color: #f5f5f5;
}

.solution .plan {
    margin-left: 300px;
}

.solution .continer {
    display: inline-block;
    margin-left: 200px;
    margin-right: 200px;
    width: 924px;
    height: 275px;
    color: #333;
    background-color: #f5f5f5;
}

.continer .pic {
    float: left;
    width: 462px;
    height: 275px;
}

.continer .text {
    float: right;
    width: 462px;
    height: 275px;
    padding: 20px;
    padding-top: 40px;
}

.continer .text .larger {
    font-weight: 700;
    font-size: 22px;
}

.continer .text .big {
    width: 422px;
    height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
}

.continer .text .small {
    width: 422px;
    height: 48px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
